function DividerLine({ size = "md" }: { size: string }) {
  return (
    <div
      className={`w-full ${
        size === "sm"
          ? " h-[0px] border border-gray-100"
          : size === "md"
          ? "h-3 bg-gray5"
          : "h-4 bg-gray5 "
      }`}
    ></div>
  );
}
export default DividerLine;
