import React, { useMemo } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { RiArrowUpSFill } from "react-icons/ri";
import { RiArrowDownSFill } from "react-icons/ri";
import CardChart from "chart/CardChart";
import Lottie from "lottie-react";
import liveRisk from "../../../../public/lottie/liveRisk.json";
import { TYPO } from "styles/Typo";
import { useTranslation } from "next-i18next";
import { toDetailPage } from "utils/navigateToDetailPage";

function MainCard({ asset, index }: any) {
  const router = useRouter();
  // const parsedChartData =
  //   asset.CHART_5MIN &&
  //   JSON.parse(asset.CHART_5MIN).map((entry: any) => ({
  //     x: entry.TIME,
  //     cvar: entry.EXP_CVaRNTS,
  //   }));

  const { t } = useTranslation("main");
  return (
    <div
      onClick={() => toDetailPage(router, asset.ITEM_CD_DL)}
      className="realtime-risk cursor-pointer w-[148px] p-4 bg-slate-100 rounded-2xl flex-col justify-start items-start gap-2.5 inline-flex"
    >
      <div className="w-[116px] justify-start items-start gap-2.5 inline-flex">
        <div className="relative w-2.5 justify-center items-center inline-flex h-[24px]">
          <p
            className={`absolute top-0 left-[0%] text-center text-neutral-700 text-base font-medium  leading-normal`}
            style={{ transform: "translateX(calc(-50% + 5px))" }}
          >
            {index}
          </p>
        </div>

        <div className="w-24  flex-col justify-center items-start gap-2 inline-flex">
          <div className=" flex-col justify-center items-start gap-1 inline-flex w-full">
            <p
              className={`${TYPO.TYPO6_MD} text-gray90 overflow-hidden truncate w-full`}
            >
              {router.locale === "ko" ? asset.ITEM_KR_NM : asset.ITEM_ENG_NM}
            </p>
            {/* <div className="w-24 justify-start items-center gap-1 inline-flex">
              <div>
                <span
                  className={`${TYPO.TYPO8_MD} text-gray60 whitespace-nowrap`}
                >
                  {t("max")}
                </span>
              </div>
              <div className="w-[71px] h-7 justify-start items-center gap-0.5 inline-flex">
                <span className={`${TYPO.TYPO4_SB} text-blue50`}>
                  {asset.EXP_CVaRNTS_95.toFixed(1)}%
                </span>
                <Image
                  src={`/images/icons/priceDown.svg`}
                  width={20}
                  height={20}
                  alt={`CVaR`}
                />
              </div>
            </div> */}
          </div>
          <div className="border-dashed w-full border-b-[1.5px]  border-gray20 h-[1px]"></div>
          <div className="w-full flex flex-col">
            <span className={`${TYPO.TYPO8_MD} text-gray50`}>{t("5mins")}</span>
            <div className="w-24 justify-start items-center gap-1 inline-flex">
              <div>
                <span
                  className={`${TYPO.TYPO6_MD} text-gray60 whitespace-nowrap`}
                >
                  {t("max")}
                </span>
              </div>
              <div className="h-7 justify-start items-center gap-0.5 inline-flex">
                <span className={`${TYPO.TYPO6_SB} text-blue50`}>
                  {asset.EXP_CVaRNTS_95.toFixed(1)}%
                </span>
                <Image
                  src={`/images/icons/priceDown.svg`}
                  width={16}
                  height={16}
                  alt={`CVaR`}
                />
              </div>
            </div>
          </div>
          {/* <div>
            <CardChart chartData={parsedChartData} />
          </div> */}
          {/* <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="h-[18px] justify-start items-center gap-1 inline-flex"
          >
            <Lottie
              animationData={liveRisk}
              renderer="svg"
              autoplay
              loop
              style={{ width: 7, height: 7 }}
            />
            <span className={`${TYPO.TYPO9_RG} text-gray50`}>
              {t("riskChart")}
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default MainCard;
