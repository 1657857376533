import { useMemo } from 'react';

interface DateItem {
    [key: string]: any;
}

function formatDate(dateStr: string): string {
    const date: Date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1).toString();
    const day = date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString();
    const hour = date.getHours() >= 10 ? date.getHours().toString() : '0' + date.getHours().toString();
    const minute = date.getMinutes() >= 10 ? date.getMinutes().toString() : '0' + date.getMinutes().toString();
    const second = date.getSeconds() >= 10 ? date.getSeconds().toString() : '0' + date.getSeconds().toString();

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

function useUdtDate(data: DateItem[] | string, isValidating: boolean, isDirectDate: boolean = false, datePropName: string = 'UDT_DT'): string {
    return useMemo(() => {
        if (Array.isArray(data) && data.length > 0 && !isValidating) {
            const firstData = data[0];
            const dateStr = isDirectDate ? firstData : firstData[datePropName];
            return formatDate(dateStr);
        } else if (!Array.isArray(data) && !isValidating) {
            return formatDate(data);
        } else {
            return '';
        }
    }, [data, isValidating, isDirectDate, datePropName]);
}



export function formatDateTime({
    date,
    includeYear = false,
}: {
    date: string;
    includeYear?: boolean;
}) {
    const dateTime = new Date(date);

    const koreanYear = String(dateTime.getFullYear()).slice(2);
    const koreaMonth = String(dateTime.getMonth() + 1).padStart(2, "0"); // 월
    const koreaDay = String(dateTime.getDate()).padStart(2, "0"); // 일


    if (includeYear) return `${koreanYear}.${koreaMonth}.${koreaDay}`;
    return `${koreaMonth}.${koreaDay}`;
}


//현재 한국 시간
export const getCurrentKSTDate = () => {
    // UTC 시간을 기준으로 현재 시간을 구합니다.
    const now = new Date();
    // UTC 시간에 한국 시간대의 차이(9시간)를 밀리초 단위로 계산하여 더합니다.
    const kstOffset = 9 * 60 * 60 * 1000; // 9 hours in milliseconds
    // 현재 UTC 시간에 대한 밀리초 + KST 오차 밀리초를 더하고, 이를 다시 Date 객체로 변환합니다.
    const kstDate = new Date(
        now.getTime() + kstOffset + now.getTimezoneOffset() * 60 * 1000
    );
    return kstDate;
};


export default useUdtDate