import { TYPO } from "styles/Typo";

function Title({ children, ...props }: any) {
  if (props.type === "horizontal") {
    return (
      <div className={` w-full bg-white flex flex-col gap-1  px-6 py-4`}>
        <div className={`gap-3 flex items-center`}>
          <h1
            className={` ${
              props?.size === "lg" ? `${TYPO.TYPO3_B}` : `${TYPO.TYPO4_B}`
            } text-gray80 whitespace-pre-line`}
          >
            {props.title}
          </h1>
          <span className={`${TYPO.TYPO6_RG} text-gray60`}>
            {props?.subTitle1}
          </span>
        </div>
        <span className={`${TYPO.TYPO8_RG} text-gray40`}>
          {props?.subTitle2}
        </span>
      </div>
    );
  } else {
    return (
      <div className={`w-full bg-white flex gap-4  px-6 py-4 items-center`}>
        <div className={`grow flex flex-col gap-1`}>
          <h1
            className={`
          ${
            props?.size === "lg" ? `${TYPO.TYPO3_B}` : `${TYPO.TYPO4_B}`
          } text-gray80 break-keep whitespace-pre-line`}
          >
            {props.title}
          </h1>
          {props?.subTitle1 && (
            <span className={`${TYPO.TYPO6_RG} text-gray60`}>
              {props?.subTitle1}
            </span>
          )}
          {props?.subTitle2 && (
            <span className={`${TYPO.TYPO8_RG} text-gray40`}>
              {props?.subTitle2}
            </span>
          )}
        </div>
        {props?.buttonChildren && (
          <div className={`${TYPO.TYPO7_MD} text-gray50`}>
            {props?.buttonChildren}
          </div>
        )}
      </div>
    );
  }
}

export default Title;
