import React from "react";
import WeatherCard from "components/organisms/WeatherCard";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Title from "components/common/title";
import Button from "components/common/buttons/buttons";
import { ICONS } from "components/common/Icons";

function CountryRisk({ countryRiskData }: any) {
  const router = useRouter();
  const { t } = useTranslation("main");

  return (
    <main className="mb-3 w-full bg-white">
      <div className="flex flex-col pt-4 pb-5 gap-1">
        <Title
          title={t("countryRiskTrends")}
          buttonChildren={
            <Button
              text={t("viewAll")}
              type="link_gray"
              size="md"
              btnClass="gap-1"
              clickHandler={() => {
                router.push("/country-risk");
              }}
              icon={ICONS.FORWARD}
              iconHeight={16}
              iconWidth={16}
            />
          }
        />
        <div className=" relative" style={{ width: "calc(100%)" }}>
          <div className="w-full overflow-hidden">
            <div
              className={`${"px-[1.25rem]"} flex justify-start items-center space-x-2 overflow-x-auto`}
            >
              {countryRiskData.map((asset: any, i: number) => (
                <WeatherCard asset={asset} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CountryRisk;
