import React, { useRef, useState } from "react";
import MainCard from "components/organisms/m/MainCard";
import Image from "next/image";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";
import { useTranslation } from "next-i18next";
import HelpModal from "components/modals/HelpModal";
import { Circle, HelpLine } from "components/common/Icons";
import DividerButton from "components/common/divider/button";
import dateStatus from "utils/dateStatus";
import { KOREA } from "utils/constant/ITME_INFO";
import { CLOSE, CLOSE_ENG } from "utils/constant/STATUS";

function Header({ trendingData, isHoliday }: any) {
  const router = useRouter();
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const { t } = useTranslation("main");

  const [move, setMove] = useState(0);
  const contentsDiv = useRef<HTMLDivElement>(null);
  const entireWidth = contentsDiv.current?.clientWidth as number;
  const fixWidth = 800;
  const movePX = 450;
  const moveRight = () => {
    if (entireWidth <= movePX * move) {
      return;
    }
    setMove((prev) => prev + 1);
  };
  const moveLeft = () => {
    if (move != 0) {
      setMove((prev) => prev - 1);
    }
  };

  //종가 날짜
  let closeDate = trendingData[0] && trendingData[0].UDT_DT;

  const formatDate = () => {
    if (!Date.parse(closeDate)) {
      console.error("Invalid date string:", closeDate);
      return "Invalid date";
    }

    const month = closeDate.split("T")[0].split("-")[1];
    const day = closeDate.split("T")[0].split("-")[2];

    return router.locale === "ko"
      ? `${month}월 ${day}일`
      : `
    ${month}.${day}
    `;
  };

  const currentStatus = dateStatus(router, KOREA, isHoliday);

  return (
    <main className="w-full bg-white">
      <div className="flex flex-col  w-full ">
        <div className="gap-1 px-6 py-4">
          <div className="flex gap-1 items-center">
            <h1 className={` ${TYPO.TYPO4_B} text-gray80`}>
              {t("realtimeRiskTop30")}
            </h1>
            <div
              onClick={() => {
                setHelpModalOpen(true);
              }}
              className="cursor-pointer"
            >
              <HelpLine fill={"gray50"} height={20} width={20} />
            </div>
          </div>
          <div className="flex flex-col ">
            <span className={`${TYPO.TYPO6_RG} text-gray60`}>
              {t("rapidlyDeclineWarning")}
            </span>

            <div
              className={`${TYPO.TYPO9_RG} text-gray50 flex gap-1 items-center`}
            >
              <span>
                {router.locale === "ko" ? formatDate() : formatDate()}{" "}
              </span>
              <div className="flex gap-[2px] items-center">
                <Circle
                  fill={
                    currentStatus === CLOSE || currentStatus === CLOSE_ENG
                      ? "gray40"
                      : "blue40"
                  }
                />
                <span>{currentStatus}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="relative pb-4">
          <div className="w-full overflow-hidden">
            <div
              ref={contentsDiv}
              className={`${
                move === 0 ? "pl-[1.25rem]" : ""
              } w-fit flex justify-start items-center space-x-2 overflow-x-auto scroll`}
              style={{
                transition: "all .2s ease .2s",
                transform: `translate(${
                  entireWidth - (movePX * (move - 1) + fixWidth) <= movePX
                    ? -movePX * (move - 1) -
                      (entireWidth - (movePX * (move - 1) + fixWidth) + 20)
                    : -movePX * move
                }px)`,
              }}
            >
              {trendingData.map((asset: any, i: number) => (
                <MainCard asset={asset} key={i} index={i + 1} />
              ))}
            </div>
            <div
              onClick={() => {
                moveLeft();
              }}
              className={`${
                move === 0 && "hidden"
              }  cursor-pointer absolute z-[2] left-0 top-[50%] translate-y-[-50%] translate-x-[-50%] w-[32px] h-[32px] p-2 bg-white rounded-[50px] shadow justify-start`}
            >
              <Image
                height={30}
                width={30}
                alt="left-btn"
                src={"/images/icons/main/leftArrow.svg"}
              />
            </div>
            <div
              onClick={() => {
                moveRight();
              }}
              className={`${
                movePX * move + fixWidth >= entireWidth && "hidden"
              }   cursor-pointer absolute z-[2] right-0 top-[50%] translate-y-[-50%] translate-x-[50%] w-[32px] h-[32px] p-2 bg-white rounded-[50px] shadow justify-start`}
            >
              <Image
                height={30}
                width={30}
                alt="left-btn"
                src={"/images/icons/main/rightArrow.svg"}
              />
            </div>
          </div>
        </div>

        <DividerButton
          text={t("viewChart")}
          onClick={() => {
            router.push("/realtime-risk");
          }}
        />
      </div>
      {helpModalOpen && (
        <HelpModal
          setHelpModalOpen={setHelpModalOpen}
          isModalOpen={helpModalOpen}
        />
      )}
    </main>
  );
}

export default Header;
