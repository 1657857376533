import { ArrowForward, Search } from "components/common/Icons";
import Input from "components/common/inputs";
import Image from "next/image";
import { useRouter } from "next/router";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import useSearchList from "utils/clientAPIs/searchList";
import SearchTable from "components/templates/common/Search/SearchTable";
import useDebounce from "utils/useDebounce";

function MainSearch() {
  const { t } = useTranslation("main");
  const router = useRouter();
  const [isSearchModal, setIsSearchModal] = useState(false);

  const onClickHandler = () => {
    if (isSearchModal) return;
    setIsSearchModal(true);
  };

  //검색 쿼리 관련
  const [search, setSearch] = useState("");

  const onSearch = useCallback((query: string) => {
    setSearch(query);
    // debouncedSearch(query);
  }, []);

  // 디바운싱 적용, 300ms 지연
  const [debouncedValue, searchTranslated] = useDebounce(search, 250);
  const [data, isValid] = useSearchList({
    search: searchTranslated[0],
    search2: searchTranslated[1],
    searchOrigin: debouncedValue,
  });

  // console.log("debouncedValue:", debouncedValue);
  // console.log("search:", search);
  // console.log("data.length:", data.length);
  // console.log("data:", data);

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {isSearchModal ? (
        <div
          className={`bg-white fixed w-full h-full overflow-y-scroll z-[499] top-0 left-0 pb-[80px]`}
        >
          <div className={"flex flex-col"}>
            <div className="px-5 mb-2">
              {/** Input */}
              <div className="pt-4 flex gap-3 items-center  ">
                <Image
                  className="cursor-pointer h-6 w-6"
                  src={"/images/icons/arrowLeft24.svg"}
                  alt="arrow"
                  width={24}
                  height={24}
                  onClick={() => {
                    setIsSearchModal(false);
                  }}
                />
                <Input
                  placeholder={t("searchPlaceholder")}
                  value={search}
                  setValue={(query: string) => onSearch(query)}
                  type="box"
                  size="md"
                  width="w-full"
                  isForcusd={true}
                  // onKeyUp={handleKeyUp}
                />
              </div>
            </div>

            <SearchTable
              search={debouncedValue}
              t={t}
              data={data}
              isValid={isValid}
              // debouncedValue={debouncedValue}
            />
          </div>
        </div>
      ) : (
        <div className={` flex  flex-col pt-4 bg-white w-full`}>
          <div className="relative">
            <div
              className={`mx-5 min-h-[52px]  overflow-hidden `}
              ref={inputRef}
            >
              {/** 인풋 */}
              <div className={`w-full`}>
                <div
                  className={`
                  rounded-[30px] border-2 border-blue-600 w-full h-[52px] p-3 gap-2.5 inline-flex items-center`}
                >
                  <div className="shrink-0">
                    <Search fill={"blue50 "} height={24} width={24} />
                  </div>
                  <div className="grow">
                    <input
                      placeholder={t("explorePlaceholder")}
                      className="line-input w-full "
                      onChange={(e) => onSearch(e.target.value)}
                      onClick={onClickHandler}
                    />
                  </div>

                  <div className="w-6 h-6 relative shrink-0 ">
                    <div className="w-6 h-6 left-0 top-0 absolute  rounded-full bg-blue-600 shrink-0" />
                    <div className="w-5 h-5 left-[50%] top-[50%] absolute z-[5] translate-x-[-50%] translate-y-[-50%]">
                      <ArrowForward fill={"gray2"} height={20} width={20} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MainSearch;
