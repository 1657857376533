import Button from "components/common/buttons/buttons";
import { ICONS } from "components/common/Icons";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";
import useTrendingAsset from "utils/clientAPIs/trending";
import { toDetailPage } from "utils/navigateToDetailPage";

function Tranding({}: {}) {
  const router = useRouter();
  const { t } = useTranslation("main");
  const [trendingAsset, trendingValid] = useTrendingAsset(router);

  return (
    <div className={"flex flex-col gap-6 relative pt-6 bg-white pb-4 "}>
      <div className="flex flex-col gap-2 w-full pb-4">
        <p className={`${TYPO.TYPO7_SB} !text-[14px]  px-6`}>{t("trending")}</p>
        <div className={`w-full `}>
          <div className="flex gap-2 overflow-auto scroll px-5 h-[32px]">
            {trendingAsset.length > 0 && !trendingValid
              ? trendingAsset.map((item: any, index: number) => (
                  <Button
                    key={index}
                    text={
                      router.locale === "ko"
                        ? item.ITEM_KR_NM
                        : item.ITEM_ENG_NM
                    }
                    size="sm"
                    type="secondary"
                    icon={ICONS.FORWARD}
                    iconHeight={12}
                    iconWidth={12}
                    clickHandler={() => {
                      toDetailPage(router, item.ITEM_CD_DL);
                    }}
                    btnClass="shrink-0"
                  />
                ))
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tranding;
