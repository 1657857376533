import Button from "components/common/buttons/buttons";
import { ICONS } from "components/common/Icons";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";
import useTrendingAsset from "utils/clientAPIs/trending";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { toDetailPage } from "utils/navigateToDetailPage";

function Trending({}: {}) {
  const router = useRouter();
  const { t } = useTranslation("main");
  const [trendingAsset, trendingValid] = useTrendingAsset(router);

  //데스크탑 캐러샐
  const [move, setMove] = useState(0);
  const contentsDiv = useRef<HTMLDivElement>(null);
  const [totalWidth, setTotalWidth] = useState(0);
  const fixWidth = 800;
  const movePX = 450;
  const moveRight = () => {
    if (totalWidth <= movePX * move) {
      return;
    }
    setMove((prev) => prev + 1);
  };
  const moveLeft = () => {
    if (move != 0) {
      setMove((prev) => prev - 1);
    }
  };
  useEffect(() => {
    if (trendingAsset && contentsDiv.current) {
      const children = contentsDiv.current.children;
      let widthSum = 0;

      // 각 자식 컴포넌트의 너비 합계
      Array.from(children).forEach((child) => {
        widthSum += child.getBoundingClientRect().width;
      });

      // gap 크기 계산
      const style = getComputedStyle(contentsDiv.current);
      const gap = parseFloat(style.columnGap) || 0;

      // 전체 너비 계산: 자식 컴포넌트 너비 합 + (gap 크기 * (자식 수 - 1))
      const total = widthSum + gap * (children.length - 1);
      setTotalWidth(total);
    }
  }, [trendingAsset]);

  return (
    <div className={`relative`}>
      <div className={"flex flex-col gap-6 relative pt-6 bg-white pb-4"}>
        <div className="flex flex-col gap-2 w-full pb-4">
          <p className={`${TYPO.TYPO7_SB} !text-[14px]  px-6`}>
            {t("trending")}
          </p>
          <div className={`w-full `}>
            <div className="relative h-[32px]">
              <div className="w-full overflow-hidden">
                <div
                  ref={contentsDiv}
                  className={`${
                    move === 0 ? "pl-[1.25rem]" : ""
                  } flex justify-start items-center gap-2 overflow-x-auto scroll`}
                  style={{
                    transition: "all .2s ease .2s",
                    transform: `translate(${
                      totalWidth - (movePX * (move - 1) + fixWidth) <= movePX
                        ? -movePX * (move - 1) -
                          (totalWidth - (movePX * (move - 1) + fixWidth) + 20)
                        : -movePX * move
                    }px)`,
                    width: `${totalWidth}px`,
                  }}
                >
                  {trendingAsset.length > 0 &&
                    !trendingValid &&
                    trendingAsset.map((item: any, index: number) => (
                      <Button
                        key={index}
                        text={
                          router.locale === "ko"
                            ? item.ITEM_KR_NM
                            : item.ITEM_ENG_NM
                        }
                        size="sm"
                        type="secondary"
                        icon={ICONS.FORWARD}
                        iconHeight={12}
                        iconWidth={12}
                        clickHandler={() => {
                          toDetailPage(router, item.ITEM_CD_DL);
                        }}
                        btnClass="shrink-0"
                      />
                    ))}
                </div>

                <div
                  onClick={() => {
                    moveLeft();
                  }}
                  className={`${
                    move === 0 && "hidden"
                  }  cursor-pointer absolute z-[2] left-0 top-[50%] translate-y-[-50%] translate-x-[-50%] w-[32px] h-[32px] p-2 bg-white rounded-[50px] shadow justify-start`}
                >
                  <Image
                    height={30}
                    width={30}
                    alt="left-btn"
                    src={"/images/icons/main/leftArrow.svg"}
                  />
                </div>
                <div
                  onClick={() => {
                    moveRight();
                  }}
                  className={`${
                    movePX * move + fixWidth >= totalWidth && "hidden"
                  }   cursor-pointer absolute z-[2] right-0 top-[50%] translate-y-[-50%] translate-x-[50%] w-[32px] h-[32px] p-2 bg-white rounded-[50px] shadow justify-start`}
                >
                  <Image
                    height={30}
                    width={30}
                    alt="left-btn"
                    src={"/images/icons/main/rightArrow.svg"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trending;
