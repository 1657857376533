import React, { useEffect } from "react";

import { NowTrendingData } from "interfaces/main";

import DaliyRiskList from "components/templates/common/main/DaliyRiskList";
import SearchBar from "components/templates/common/main/SearchBar";
import Advertisement from "components/templates/common/main/Advertisement";
import MainLearn from "components/templates/common/main/Learn";
import ThemeList from "components/templates/common/industry/ThemeList";

import RealtimeRiskMobile from "components/templates/m/main/RealtimeRisk";
import RealtimeRisk from "components/templates/w/main/RealtimeRisk";

import MainSearch from "components/templates/w/main/Search";
import MainSearchMobile from "components/templates/m/main/Search";

import CountryRisk from "components/templates/w/main/CountryRisk";
import CountryRiskMobile from "components/templates/m/main/CountryRisk";
import Trending from "components/templates/w/main/Trending";
import TrendingMobile from "components/templates/m/main/Trending";

function Main({
  trendingData,
  countryRiskData,
  isHoliday,
  themeListData,
  isMobile,
}: {
  trendingData: NowTrendingData[];
  countryRiskData: NowTrendingData[];
  isHoliday?: boolean;
  themeListData?: any;
  isMobile: boolean;
}) {
  if (isMobile) {
    return (
      <main className="min-w-[360px] h-auto max-w-[800px]">
        <MainLearn />
        <MainSearchMobile />
        <TrendingMobile />
        <RealtimeRiskMobile trendingData={trendingData} isHoliday={isHoliday} />
        <DaliyRiskList />
        <ThemeList themeListData={themeListData} />
        <Advertisement />
        <SearchBar />
        <CountryRiskMobile countryRiskData={countryRiskData} />
      </main>
    );
  }

  return (
    <main className={"min-w-[360px] max-w-[800px] h-auto "}>
      <MainLearn />
      <MainSearch />
      <Trending />
      <RealtimeRisk trendingData={trendingData} isHoliday={isHoliday} />
      <DaliyRiskList />
      <ThemeList themeListData={themeListData} />
      <Advertisement />
      <SearchBar />
      <CountryRisk countryRiskData={countryRiskData} />
    </main>
  );
}

export default Main;
