import Button from "components/common/buttons/buttons";
import Image from "next/image";
import { Dispatch, SetStateAction } from "react";
import { TYPO } from "styles/Typo";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import useModalClickOutside from "utils/useModalClickOutside";
import BottomModal from "components/common/Modal/BottomModal";
import useIsMobile from "states/deviceState";
interface type {
  setHelpModalOpen: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
}
function HelpModal({ setHelpModalOpen, isModalOpen }: type) {
  const router = useRouter();

  const modalRef = useModalClickOutside(setHelpModalOpen); // 모달 참조를 위한 ref 추가

  const { t } = useTranslation("main");
  const isMobile = useIsMobile();

  return (
    <BottomModal
      isOpen={isModalOpen}
      modalRef={modalRef}
      containerClass="gap-[17px]"
    >
      <h1 className={`${TYPO.TYPO5_SB} text-gray80`}>
        {t("whatIsRealtimeRisk")}
      </h1>
      <span className={`${TYPO.TYPO6_RG} text-gray70 break-keep`}>
        {t("realtimeRiskDescription")}
      </span>
      <div className="border-dashed w-full border-b-[1.5px]  border-gray20 h-[1px]"></div>
      <div className="flex justify-center w-full ">
        <Image
          width={isMobile ? 375 : 500}
          height={isMobile ? 164 : 218}
          src={
            router.locale === "en"
              ? `/images/explain/realtimeHelpEng.png`
              : `/images/explain/realtimeHelp.png`
          }
          alt="file"
        />
      </div>
      <div className="w-full sh-[117px] p-5 bg-gray5 rounded-[10px] flex-col justify-start items-start gap-[5px] inline-flex">
        <h3 className={`${TYPO.TYPO7_SB} text-gray70`}>{t("exampleTitle")}</h3>
        <span className={`${TYPO.TYPO7_RG} text-gray70`}>
          {t("exampleDescription")}
        </span>
      </div>
      <Button
        type="primary"
        text={t("confirmButtonText")}
        size="lg"
        width="w-full"
        btnClass="mt-[3px]"
        clickHandler={() => {
          setHelpModalOpen(false);
        }}
      />
    </BottomModal>
  );
}

export default HelpModal;
