export const CLOSE = "장마감"
export const CLOSE_ENG = "Closed";
export const FIVEMIN_DELAY = "5분 지연";
export const FIVEMIN_DELAY_ENG = "5mins Delayed";
export const DAY_DELAY = "하루 지연";
export const DAY_DELAY_ENG = "24hrs Delayed";

export const sortText = [
    ["거래량 많은 순", "Large Volume"],
    ["시가총액 높은 순", "Large Market Cap"],
    ["하락 전망 높은 순", "High Loss-forecast"],
    ["하락 전망 낮은 순", "Low Loss-forecast"],
]

export const INTEREST_SORT = [['추가된 날짜 순', 'Added Date'],
['하락 전망 높은 순', 'High Risk'],
['하락 전망 낮은 순', 'Low Risk'],
['이름 순', 'Name']];

export const SAFE = 'Safe';
export const SAFE_KR = '안전';
export const MODEREATE = 'Moderate';
export const MODEREATE_KR = '적정';
export const CAUTION = 'Caution';
export const CAUTION_KR = '주의';
export const DANGER = 'Danger';
export const DANGER_KR = '위험';


export const AI = 'AI';
export const RISK = 'Risk';
export const PORTFOLIO = 'Portfolio';
export const ASSETINFO = 'Asset Info';