import React from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { RiArrowUpSFill } from "react-icons/ri";
import { RiArrowDownSFill } from "react-icons/ri";
import { TYPO } from "styles/Typo";
import { toDetailPage } from "utils/navigateToDetailPage";

function TodayCard({ asset }: any) {
  const router = useRouter();
  return (
    <div
      onClick={() => toDetailPage(router, asset.ITEM_CD_DL)}
      className="global-index cursor-pointer flex-shrink-0 w-[152px] h-[136px] bg-gray5 flex flex-col rounded-[20px] py-[14px] px-[16px] gap-[10px]"
    >
      <div className="flex flex-col ">
        <p className={`${TYPO.TYPO7_MD} text-gray80 truncate w-[100px]`}>
          {router.locale === "ko" ? asset.ITEM_KR_NM : asset.ITEM_ENG_NM}
        </p>
        <h2 className={`${TYPO.TYPO5_SB} text-gray80`}>
          {asset.CAT && asset.CAT !== "Index"
            ? router.locale === "ko"
              ? asset.ADJ_CLOSE_KRW &&
                asset.ADJ_CLOSE_KRW.toLocaleString("en-us", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }) + " 원"
              : asset.ADJ_CLOSE_USD &&
                asset.ADJ_CLOSE_USD.toLocaleString("en-us", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }) + " ＄"
            : asset.ADJ_CLOSE &&
              asset.ADJ_CLOSE.toLocaleString("en-us", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
        </h2>
        {asset.ADJ_CLOSE_CHG && asset.ADJ_CLOSE_CHG > 0 ? (
          <div className={`flex items-center ${TYPO.TYPO8_MD}`}>
            <RiArrowUpSFill color={"red"} />
            <p className={"text-red-500 mr-1 whitespace-nowrap"}>
              {asset.ADJ_CLOSE_CHANGE &&
                asset.ADJ_CLOSE_CHANGE.toLocaleString("en-us", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
            </p>
            <p className={"text-red-500 whitespace-nowrap"}>
              {asset.ADJ_CLOSE_CHG &&
                "(" +
                  asset.ADJ_CLOSE_CHG.toLocaleString("en-us", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }) +
                  " %" +
                  ")"}
            </p>
          </div>
        ) : (
          <div
            onClick={() => toDetailPage(router, asset.ITEM_CD_DL)}
            className={`flex items-center ${TYPO.TYPO8_MD}`}
          >
            <RiArrowDownSFill color={"blue"} />
            <p className={"text-blue-500 mr-1 whitespace-nowrap"}>
              {asset.ADJ_CLOSE_CHANGE &&
                Math.abs(asset.ADJ_CLOSE_CHANGE).toLocaleString("en-us", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
            </p>
            <p className={"text-blue-500 whitespace-nowrap"}>
              {asset.ADJ_CLOSE_CHG &&
                "(" +
                  asset.ADJ_CLOSE_CHG.toLocaleString("en-us", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }) +
                  " %" +
                  ")"}
            </p>
          </div>
        )}
      </div>

      <div className="border-dashed w-full border-b-[1.5px]  border-gray20 h-[1px]"></div>
      <div className={`${TYPO.TYPO8_MD} text-gray60 whitespace-nowrap`}>
        {router.locale === "ko"
          ? ` ${
              asset?.CVaR_LV_KR
            }・최대 -${asset?.EXP_CVaRNTS_95?.toLocaleString("en-us", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })}% 전망`
          : `Up to -${asset?.EXP_CVaRNTS_95?.toLocaleString("en-us", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })}%・${asset?.CVaR_LV}`}
      </div>
      {/*<div className="space-y-1">
        <div className="flex  items-center space-y-1 space-x-1">
          <Image
            src={`/images/weather/${asset.WTHR_ENG_NM}.svg`}
            width={28}
            height={28}
            alt={`weather`}
          />
          <p className={`${TYPO.TYPO8_MD} text-gray80`}>
            {router.locale === "ko"
              ? asset.WTHR_KR_DL && asset.WTHR_KR_DL
              : asset.WTHR_ENG_DL && asset.WTHR_ENG_DL}
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default TodayCard;
