import { useRouter } from "next/router";
import { useEffect } from "react";
import { debounce } from "./debounce";

const useRestoreScroll = (pageName: string, delay: number = 150) => {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            sessionStorage.setItem(`${pageName}-scrollPosition`, window.scrollY.toString());
        };
        router.events.on("routeChangeStart", handleRouteChange);
        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
        };
    }, [router.events, pageName]);

    useEffect(() => {
        const debouncedRestoreScroll = debounce(() => {
            const scrollPosition = sessionStorage.getItem(`${pageName}-scrollPosition`);
            if (scrollPosition) {
                window.scrollTo(0, Number(scrollPosition));
                sessionStorage.removeItem(`${pageName}-scrollPosition`);
            }
        }, delay);

        debouncedRestoreScroll();
    }, [delay, pageName]);

};

export default useRestoreScroll;
