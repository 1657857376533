import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

import { TYPO } from "styles/Typo";
import Icon, { ICONS } from "components/common/Icons";

function MainLearn() {
  const router = useRouter();
  const { data: sessions } = useSession();

  const contents = [
    {
      subTitle: "포트폴리오 분석 기능 출시",
      subTitleEn: "Portfolio Analysis Launched",
      title: "내 포트폴리오 보러가기",
      titleEn: "Go to My Portfolio",
      icon: PortfolioIcon,
      routing: "/portfolio",
    },
    {
      subTitle: "관심 자산 리스크가 달라지면",
      subTitleEn: "When assets’ risk changes",
      title: "매일 무료로 알림 받기",
      titleEn: "Get daily free alerts",
      icon: WatchListIcon,
      routing: sessions?.user ? "/profile/alarm?from=main" : "/login",
    },
    {
      subTitle: "잃지 않는 투자를 위한",
      subTitleEn: "For No-Loss Investments",
      title: "리스크웨더 사용법",
      titleEn: "Riskweather Guide",
      icon: LearnIcon,
      routing: "/learn",
    },
  ];

  return (
    <div id="main-noti">
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2800,
          disableOnInteraction: false,
        }}
        speed={1000}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
      >
        <div className="relative  py-6 ">
          {contents.map((content, index) => (
            <SwiperSlide key={index}>
              <div
                className="bg-white rounded-[24px]  flex gap-4 items-center p-5 mx-6 cursor-pointer"
                style={{ width: "calc(100%-2rem)" }}
                onClick={() => router.push(content.routing)}
              >
                <div>{content.icon()}</div>
                <div className="grow flex flex-col">
                  <span className={`${TYPO.TYPO7_MD} text-gray50`}>
                    {router.locale === "ko"
                      ? content.subTitle
                      : content.subTitleEn}
                  </span>
                  <span className={`${TYPO.TYPO5_1_SB} text-blue40`}>
                    {router.locale === "ko" ? content.title : content.titleEn}
                  </span>
                </div>
                <div className="shrink-0">
                  <Icon
                    name={ICONS.FORWARD}
                    height={16}
                    width={16}
                    fill="gray30"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </div>
        <div
          className="h-[20px] bottom-0 absolute w-full"
          style={{
            background: "linear-gradient(180deg, #f2f4f8 0%, #FFF 100%)",
          }}
        ></div>
      </Swiper>
    </div>
  );
}
const PortfolioIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M6.41761 29.5824C5.78496 30.2151 4.75284 30.2194 4.17961 29.5324C2.2567 27.228 0.934748 24.472 0.345884 21.5116C-0.348646 18.02 0.00781095 14.4008 1.37019 11.1117C2.73257 7.82264 5.03967 5.01142 7.99976 3.03355C10.9598 1.05569 14.44 7.53914e-06 18 7.47549e-06C21.5601 7.41185e-06 25.0402 1.05569 28.0003 3.03355C30.9604 5.01142 33.2675 7.82264 34.6298 11.1117C35.9922 14.4008 36.3487 18.02 35.6542 21.5116C35.0653 24.472 33.7433 27.228 31.8204 29.5324C31.2472 30.2194 30.2151 30.2151 29.5824 29.5824C28.9498 28.9498 28.9578 27.929 29.5174 27.2309C30.9943 25.3881 32.0126 23.2112 32.4764 20.8795C33.0459 18.0164 32.7536 15.0486 31.6365 12.3516C30.5193 9.65456 28.6275 7.34937 26.2002 5.72752C23.773 4.10567 20.9193 3.24001 18 3.24001C15.0808 3.24001 12.2271 4.10567 9.7998 5.72752C7.37253 7.34937 5.48071 9.65456 4.36356 12.3516C3.24641 15.0486 2.95411 18.0164 3.52363 20.8795C3.98742 23.2112 5.00577 25.3881 6.48268 27.2309C7.04223 27.929 7.05026 28.9498 6.41761 29.5824Z"
        fill="#DAE1E9"
      />
      <path
        d="M23.5899 19.3969C23.6016 17.9086 24.0235 17.4516 24.7617 16.9945C25.2657 16.6781 25.6407 16.2797 25.6407 15.6938C25.6407 15.0375 25.1367 14.6156 24.4922 14.6156C23.9063 14.6156 23.3438 14.9906 23.3086 15.7758H21.8438C21.8789 14.2055 23.0625 13.3969 24.5039 13.3969C26.086 13.3969 27.1758 14.2758 27.1758 15.6703C27.1758 16.6195 26.6953 17.2406 25.9336 17.6977C25.2539 18.1078 24.9727 18.518 24.961 19.3969V19.5024H23.5899V19.3969ZM24.3164 22.0922C23.8125 22.0922 23.4024 21.682 23.4024 21.1781C23.4024 20.6859 23.8125 20.2758 24.3164 20.2758C24.7969 20.2758 25.2188 20.6859 25.2188 21.1781C25.2188 21.682 24.7969 22.0922 24.3164 22.0922Z"
        fill="url(#paint0_linear_12269_36388)"
      />
      <path
        d="M17.3672 19.3969C17.3789 17.9086 17.8008 17.4516 18.5391 16.9945C19.043 16.6781 19.418 16.2797 19.418 15.6938C19.418 15.0375 18.9141 14.6156 18.2696 14.6156C17.6836 14.6156 17.1211 14.9906 17.086 15.7758H15.6211C15.6563 14.2055 16.8399 13.3969 18.2813 13.3969C19.8633 13.3969 20.9532 14.2758 20.9532 15.6703C20.9532 16.6195 20.4727 17.2406 19.711 17.6977C19.0313 18.1078 18.75 18.518 18.7383 19.3969V19.5024H17.3672V19.3969ZM18.0938 22.0922C17.5899 22.0922 17.1797 21.682 17.1797 21.1781C17.1797 20.6859 17.5899 20.2758 18.0938 20.2758C18.5742 20.2758 18.9961 20.6859 18.9961 21.1781C18.9961 21.682 18.5742 22.0922 18.0938 22.0922Z"
        fill="url(#paint1_linear_12269_36388)"
      />
      <path
        d="M11.1446 19.3969C11.1563 17.9086 11.5782 17.4516 12.3164 16.9945C12.8203 16.6781 13.1953 16.2797 13.1953 15.6938C13.1953 15.0375 12.6914 14.6156 12.0469 14.6156C11.461 14.6156 10.8985 14.9906 10.8633 15.7758H9.39847C9.43362 14.2055 10.6172 13.3969 12.0586 13.3969C13.6407 13.3969 14.7305 14.2758 14.7305 15.6703C14.7305 16.6195 14.25 17.2406 13.4883 17.6977C12.8086 18.1078 12.5274 18.518 12.5157 19.3969V19.5024H11.1446V19.3969ZM11.8711 22.0922C11.3672 22.0922 10.9571 21.682 10.9571 21.1781C10.9571 20.6859 11.3672 20.2758 11.8711 20.2758C12.3516 20.2758 12.7735 20.6859 12.7735 21.1781C12.7735 21.682 12.3516 22.0922 11.8711 22.0922Z"
        fill="url(#paint2_linear_12269_36388)"
      />
      <path
        d="M6.41761 29.5824C5.78496 30.2151 4.75284 30.2194 4.17961 29.5324C1.85925 26.7518 0.423098 23.3244 0.0799122 19.694C-0.314276 15.5239 0.758196 11.3469 3.11258 7.88251C5.46696 4.41814 8.95584 1.88332 12.9782 0.714721C16.4801 -0.302669 20.1954 -0.229074 23.635 0.904727C24.4847 1.18483 24.8606 2.14607 24.5053 2.96719C24.15 3.7883 23.1979 4.15659 22.3428 3.89333C19.6009 3.04917 16.6596 3.01913 13.8821 3.82607C10.5838 4.78433 7.72291 6.86287 5.79232 9.70366C3.86173 12.5444 2.9823 15.9696 3.30553 19.389C3.57773 22.2686 4.68841 24.9922 6.48268 27.2309C7.04223 27.929 7.05026 28.9498 6.41761 29.5824Z"
        fill="url(#paint3_linear_12269_36388)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12269_36388"
          x1="-86.7166"
          y1="15.9038"
          x2="41.5649"
          y2="15.9038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#176CFF" />
          <stop offset="1" stopColor="#9C1EFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12269_36388"
          x1="-44.7033"
          y1="15.9038"
          x2="83.5781"
          y2="15.9038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#176CFF" />
          <stop offset="1" stopColor="#9C1EFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12269_36388"
          x1="-0.806856"
          y1="15.9038"
          x2="37.6691"
          y2="15.9038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#176CFF" />
          <stop offset="1" stopColor="#9C1EFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12269_36388"
          x1="18"
          y1="-2.25"
          x2="18"
          y2="31.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#176CFF" />
          <stop offset="1" stopColor="#9C1EFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const WatchListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M21.75 27.75C21.75 28.2425 21.653 28.7301 21.4645 29.1851C21.2761 29.64 20.9999 30.0534 20.6517 30.4017C20.3034 30.7499 19.89 31.0261 19.4351 31.2145C18.9801 31.403 18.4925 31.5 18 31.5C17.5075 31.5 17.0199 31.403 16.5649 31.2145C16.11 31.0261 15.6966 30.7499 15.3483 30.4017C15.0001 30.0534 14.7239 29.64 14.5355 29.1851C14.347 28.7301 14.25 28.2425 14.25 27.75L21.75 27.75Z"
        fill="#F3A50E"
      />
      <path
        d="M29.5 8.5C29.5 10.1569 28.1569 11.5 26.5 11.5C24.8431 11.5 23.5 10.1569 23.5 8.5C23.5 6.84315 24.8431 5.5 26.5 5.5C28.1569 5.5 29.5 6.84315 29.5 8.5Z"
        fill="#FF0000"
      />
      <path
        d="M22.4416 5.57892C21.8491 6.40066 21.5 7.40956 21.5 8.5C21.5 11.2614 23.7386 13.5 26.5 13.5C26.6687 13.5 26.8355 13.4916 27 13.4753V24.75H27.75C28.5784 24.75 29.25 25.4216 29.25 26.25C29.25 27.0784 28.5784 27.75 27.75 27.75H8.25C7.83438 27.75 7.45824 27.581 7.18657 27.3079C6.91331 27.0362 6.74414 26.6599 6.74414 26.2441C6.74414 25.4162 7.41639 24.7449 8.24414 24.7441H9V13.4549C9 11.3729 9.625 9.52303 10.875 7.90514C12.125 6.28726 14.1 5.02667 15.75 4.7258C17.4 4.42494 18.6 4.42452 20.25 4.7258C20.9488 4.8534 21.7059 5.153 22.4416 5.57892Z"
        fill="#FFC633"
      />
    </svg>
  );
};

const LearnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <rect
        x="22.5"
        y="13.5"
        width="7.5"
        height="7.5"
        rx="3.75"
        fill="#0F62FE"
      />
      <path
        d="M7.5 13.2127C7.5 12.977 7.5 12.8592 7.53143 12.754C7.57008 12.6248 7.64287 12.5083 7.74214 12.417C7.82288 12.3427 7.92883 12.2911 8.14071 12.1879L25.3607 3.79863C25.8557 3.55749 26.1032 3.43693 26.3047 3.45165C26.5518 3.46971 26.774 3.60866 26.8985 3.82292C27 3.99762 27 4.2729 27 4.82347V29.6765C27 30.2271 27 30.5024 26.8985 30.6771C26.774 30.8913 26.5518 31.0303 26.3047 31.0484C26.1032 31.0631 25.8557 30.9425 25.3607 30.7014L8.14071 22.3121C7.92883 22.2089 7.82288 22.1573 7.74214 22.083C7.64287 21.9917 7.57008 21.8752 7.53143 21.746C7.5 21.6408 7.5 21.523 7.5 21.2873V13.2127Z"
        fill="#4589FF"
      />
      <rect x="6" y="10.5" width="6" height="13.5" fill="#0F62FE" />
    </svg>
  );
};
export default MainLearn;
