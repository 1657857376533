import DividerLine from "components/common/divider/line";
import Image from "next/image";
import { useEffect, useState } from "react";

function Advertisement() {
  const [adImage, setAdImage] = useState({ randomImage: "", link: "" });
  const images = ["AD1.png", "AD2.png"];
  const Links = [
    "http://www.juroinstruments.com/",
    "http://app.kbsec.com/scr/Mable.html?id=5080",
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setAdImage({ randomImage: images[randomIndex], link: Links[randomIndex] });
  }, []);

  return (
    <>
      <div
        className="w-full bg-white h-[70px] cursor-pointer "
        onClick={() => {
          window.open(adImage.link, "_blank");
        }}
      >
        {adImage?.randomImage && (
          <Image
            src={`/images/ads/${adImage?.randomImage}`}
            alt="ads"
            width={375}
            height={70}
            className="w-full h-full object-contain"
          />
        )}
      </div>
      <DividerLine size={"md"} />
    </>
  );
}
export default Advertisement;
