import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Title from "components/common/title";
import RiskTable from "components/organisms/RiskTable";
import { KOREA, US } from "utils/constant/ITME_INFO";

function DaliyRiskList() {
  const router = useRouter();
  const [type, setType] = useState(router.locale === "en" ? US : KOREA);

  useEffect(() => {
    if (sessionStorage.getItem("highrisk-type")) {
      setType(sessionStorage.getItem("highrisk-type") as string);
      sessionStorage.removeItem("highrisk-type");
    }
  }, []);
  const { t } = useTranslation("main");

  return (
    <main className="w-full bg-white">
      <div className="flex flex-col  pt-4">
        <Title
          title={
            <div>
              <span className="text-gray80">{t("dailyRisk")}</span>
            </div>
          }
          subTitle1={t("riskAnalysisResult")}
        />

        <RiskTable type={type} setType={setType} />
      </div>
    </main>
  );
}

export default DaliyRiskList;
