import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import InputStyle from "components/common/inputStyle";
import Title from "components/common/title";
import DividerLine from "components/common/divider/line";
import Text from "components/common/List/Text";

function SearchBar() {
  const router = useRouter();
  const { t } = useTranslation("main");

  const ListComponents = [
    {
      url: "/asset?type=korea&sort=risk",
      src: "/images/icons/koreanFlag.svg",
      title: t("domesticMajorAssets"),
      count: t("numberOfDomesticAssets"),
    },
    {
      url: "/asset?type=us&sort=risk",
      src: "/images/icons/globe.svg",
      title: t("foreignMajorAssets"),
      count: t("numberOfForeignAssets"),
    },
    {
      url: "/asset?type=crypto&sort=risk",
      src: "/images/icons/crypto.svg",
      title: t("cryptoAssets"),
      count: t("numberOfCryptoAssets"),
    },
  ];

  return (
    <main className="w-full   bg-white">
      <div className="flex flex-col ">
        <Title title={t("findAssets")} />

        <div className="px-5 mb-4" onClick={() => router.push("/search")}>
          <InputStyle
            placeholder={t("searchAssets")}
            type={"box"}
            size={"md"}
            search
            width="w-full"
          />
        </div>

        <div className="space-y-7  px-5 pb-5">
          {ListComponents.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                router.push(item.url);
              }}
              className="cursor-pointer flex items-center justify-between"
            >
              <div className="flex items-center gap-3">
                <div className="p-[5px]">
                  <Image
                    src={item.src}
                    alt="flag"
                    width={30}
                    height={30}
                    className="h-[30px] w-[30px]"
                  />
                </div>

                <div className="flex flex-col">
                  <Text item={item.title} price={item.count} />
                </div>
              </div>
              <Image
                src="/images/icons/arrowRight.svg"
                width={20}
                height={20}
                alt="arrow"
              />
            </div>
          ))}
        </div>
      </div>
      <DividerLine size={"md"} />
    </main>
  );
}

export default SearchBar;
