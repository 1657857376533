import React, { useRef, useState } from "react";
import WeatherCard from "components/organisms/WeatherCard";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Title from "components/common/title";
import { ICONS } from "components/common/Icons";
import Button from "components/common/buttons/buttons";

function CountryRisk({ countryRiskData }: any) {
  const [move, setMove] = useState(0);
  const contentsDiv = useRef<HTMLDivElement>(null);
  const entireWidth = contentsDiv.current?.clientWidth as number;
  const fixWidth = 800;

  const movePX = 450;

  const moveRight = () => {
    if (entireWidth <= movePX * move) {
      return;
    }
    setMove((prev) => prev + 1);
  };
  const moveLeft = () => {
    if (move != 0) {
      setMove((prev) => prev - 1);
    }
  };

  const router = useRouter();
  const { t } = useTranslation("main");

  return (
    <main className="w-full bg-white">
      <div className="flex flex-col  pt-4 pb-5  space-y-2">
        <Title
          title={t("countryRiskTrends")}
          buttonChildren={
            <Button
              text={t("viewAll")}
              type="link_gray"
              size="md"
              btnClass="gap-1"
              clickHandler={() => {
                router.push("/country-risk");
              }}
              icon={ICONS.FORWARD}
              iconHeight={16}
              iconWidth={16}
            />
          }
        />

        <div className="relative" style={{ width: "calc(100%)" }}>
          <div className="w-full overflow-hidden">
            <div
              ref={contentsDiv}
              className={`${
                move === 0 ? "pl-[1.25rem]" : ""
              } w-fit flex justify-start items-center space-x-2 overflow-x-auto scroll`}
              style={{
                transition: "all .2s ease .2s",
                transform: `translate(${
                  entireWidth - (movePX * (move - 1) + fixWidth) <= movePX
                    ? -movePX * (move - 1) -
                      (entireWidth - (movePX * (move - 1) + fixWidth) + 20)
                    : -movePX * move
                }px)`,
              }}
            >
              {countryRiskData.map((asset: any, i: number) => (
                <WeatherCard asset={asset} key={i} />
              ))}
            </div>
            <div
              onClick={() => {
                moveLeft();
              }}
              className={`${
                move === 0 && "hidden"
              } cursor-pointer absolute z-[2] left-0 top-[50%] translate-y-[-50%] translate-x-[-50%] w-[32px] h-[32px] p-2 bg-white rounded-[50px] shadow justify-start`}
            >
              <Image
                height={30}
                width={30}
                alt="left-btn"
                src={"/images/icons/main/leftArrow.svg"}
              />
            </div>
            <div
              onClick={() => {
                moveRight();
              }}
              className={`${
                movePX * move + fixWidth >= entireWidth && "hidden"
              }  cursor-pointer absolute z-[2] right-0 top-[50%] translate-y-[-50%] translate-x-[50%] w-[32px] h-[32px] p-2 bg-white rounded-[50px] shadow justify-start`}
            >
              <Image
                height={30}
                width={30}
                alt="left-btn"
                src={"/images/icons/main/rightArrow.svg"}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CountryRisk;
