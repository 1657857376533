import useSWR from "swr";
import { fetcher } from "./fetcher";

const useCVarRank = ({ type, order, isDetail = false }: { type: string | undefined, order: string | undefined, isDetail?: boolean }): [any, boolean] => {
    const { data: explodeDt, isValidating } = useSWR(
        `/api/cvarRank?type=${type}&order=${order}&detail=${isDetail}`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,

    }
    );
    const isValid = !explodeDt && isValidating;
    const data = explodeDt ? [].concat(...explodeDt) : [];
    return [data, isValid];
}
export default useCVarRank;