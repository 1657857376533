import { CRYPTO, KOREA, US } from "./constant/ITME_INFO";
import { CLOSE, CLOSE_ENG, DAY_DELAY, DAY_DELAY_ENG, FIVEMIN_DELAY, FIVEMIN_DELAY_ENG } from "./constant/STATUS";
import { getCurrentKSTDate } from "./useFormattedDate";

const dateStatus = (router: any, cat: string | "", isHoliday: boolean) => {
    const close = router.locale === "ko" ? CLOSE : CLOSE_ENG;
    const fiveMinDelay = router.locale === "ko" ? FIVEMIN_DELAY : FIVEMIN_DELAY_ENG;
    const dayDelay = router.locale === "ko" ? DAY_DELAY : DAY_DELAY_ENG;
    const nothing = "";


    const now = getCurrentKSTDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // 한국 자산
    if (cat === KOREA) {
        if (isHoliday) return close;
        if (hours >= 9 && (hours < 15 || (hours === 15 && minutes <= 30))) {
            return fiveMinDelay;
        }
        else return close;
    }

    // 미국 자산
    if (cat === US) {
        if (isHoliday) return close;
        if ((hours >= 22 && hours <= 23) || (hours >= 0 && hours < 5)) {
            return dayDelay;
        }
        else return close;
    }

    //가상자산
    if (cat === CRYPTO) return fiveMinDelay;

    //그외
    return nothing;
}

export default dateStatus;