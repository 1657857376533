import Tab from "components/common/Tab/tab";
import Tabs from "components/common/Tab/tabs";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import ListWithInterest from "../components/ListWithInterest";
import Loading from "components/organisms/m/Loading";
import { TYPO } from "styles/Typo";
import useIsMobile from "states/deviceState";
import EmptySearchItem from "../components/EmptySearchItem";

function SearchTable({
  search,
  t,
  data,
  isValid,
}: {
  search?: string;
  t: any;
  data: any;
  isValid: boolean;
}) {
  const router = useRouter();
  const [type, setType] = useState(
    router.locale === "en" ? "United States" : "Korea (South)"
  );
  const [typeData, setTypeData] = useState([...(data || [])]);

  // console.log("Search:", search);
  useEffect(() => {
    // console.log("uef");
    if (!search && !isValid) {
      let sorted = [...(data || [])];
      if (type === "Korea (South)") {
        sorted = sorted.filter((item) => item.ITEM_CD.toString()[0] == 5);
      } else if (type === "United States") {
        sorted = sorted.filter((item) => item.ITEM_CD.toString()[0] == 2);
      } else if (type === "Crypto") {
        sorted = sorted.filter((item) => item.ITEM_CD.toString()[0] == 3);
      }
      sorted.sort((a, b) => a.RANK - b.RANK);
      setTypeData(sorted);
    }
  }, [type, search, isValid]);

  useEffect(() => {
    if (data.length >= 0 && search) {
      setTypeData(data);
    }
  }, [isValid, search]);

  const currentDate = new Date();
  const year = currentDate.getFullYear() % 100; // 2024년의 경우 24로 변환
  const month = currentDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줌
  const day = currentDate.getDate();

  const isMobile = useIsMobile();
  return (
    <div className={`${isMobile ? "" : "h-[545px] scroll"} overflow-y-scroll`}>
      {/**검색 모달 default TOP UI */}
      {search?.length === 0 && (
        <>
          <Tabs type="line" isFull={true}>
            <Tab
              title={t("korean")}
              onClick={() => {
                setType("Korea (South)");
                // router.push(`/?type=kr`, undefined, { scroll: false });
              }}
              isActive={type === "Korea (South)"}
              type={"line"}
            />
            <Tab
              title={t("global")}
              onClick={() => {
                setType("United States");
                // router.push(`/?type=us`, undefined, { scroll: false });
              }}
              isActive={type === "United States"}
              type={"line"}
            />
            <Tab
              title={t("crypto")}
              onClick={() => {
                setType("Crypto");
                // router.push(`/?type=crypto`, undefined, {
                //   scroll: false,
                // });
              }}
              isActive={type === "Crypto"}
              type={"line"}
            />
          </Tabs>
          <div className="px-6 py-4 w-full flex-col flex">
            <span className={`${TYPO.TYPO7_MD} !text-[14px] text-gray50`}>
              {router.locale === "ko"
                ? `${year}년 ${month}월 ${day}일`
                : `${year}.${month}.${day}`}
            </span>
            <span className={`${TYPO.TYPO6_SB} text-gray80`}>
              {t("trending")}
            </span>
          </div>
        </>
      )}

      {/**검색 리스트 - default 사람들이 가장 많이 보는 자산 */}
      <div>
        {typeData.length > 0 && !isValid && (
          <ListWithInterest
            data={typeData}
            count={search && search?.length > 0 ? 100 : 10}
            type={type}
            isSearching={(search && search?.length > 0) || false}
          />
        )}

        {typeData.length === 0 && !isValid && <EmptySearchItem />}
        {isValid && <Loading />}
      </div>
    </div>
  );
}

export default SearchTable;
