import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Tabs from "components/common/Tab/tabs";
import Tab from "components/common/Tab/tab";
import DividerButton from "components/common/divider/button";
import Loading from "./m/Loading";
import DividerLine from "components/common/divider/line";
import { Expand, Grade } from "components/common/Icons";
import useInterest from "utils/clientAPIs/interest";
import { TYPO } from "styles/Typo";
import SortedSelectModal from "components/modals/SortedSelectModal";
import { CRYPTO, KOREA, US } from "utils/constant/ITME_INFO";
import { sortText } from "utils/constant/STATUS";
import { useRiskTable } from "utils/hooks/RiskTable";

interface Props {
  type: string;
  setType: Dispatch<SetStateAction<string>>;
}

function RiskTable({ type, setType }: Props) {
  const router = useRouter();
  const { t } = useTranslation("main");
  const [
    session,
    update,
    userInfo,
    mutate,
    interestedAssets,
    interestMutate,
    interestValid,
  ] = useInterest();

  useEffect(() => {
    if (userInfo && userInfo.user && userInfo.user.interest) {
      interestMutate();
    }
  }, [userInfo, interestMutate]);

  const { data, isValid, sort, setSort, ListComponents } = useRiskTable(type);

  const [isSortModal, setIsSortModal] = useState(false);

  const TabContents = [
    {
      title: t("korean"),
      type: KOREA,
      url: "/?type=kr",
    },
    {
      title: t("global"),
      type: US,
      url: "/?type=us",
    },
    {
      title: t("crypto"),
      type: CRYPTO,
      url: "/?type=crypto",
    },
  ];

  return (
    <div className="flex flex-col ">
      <Tabs type="line" isFull={true}>
        {TabContents.map((item, i) => (
          <Tab
            key={i}
            title={item.title}
            onClick={() => {
              setType(item.type);
              router.push(item.url, undefined, {
                scroll: false,
                shallow: true,
              });
            }}
            isActive={type === item.type}
            type={"line"}
          />
        ))}
      </Tabs>
      <div>
        <div className={`${TYPO.TYPO7_MD} text-gray60 px-6 mt-4`}>
          <div
            className="flex items-center gap-1 cursor-pointer w-fit"
            onClick={() => {
              window.dataLayer.push({
                event: "ClickMainSorting",
              });
              setIsSortModal(true);
            }}
          >
            <span>{sortText[sort][router.locale === "ko" ? 0 : 1]}</span>
            <Expand fill="gray60" height={12} width={12} />
          </div>
          {isSortModal && (
            <SortedSelectModal
              data={sortText.map((item) =>
                router.locale === "ko" ? item[0] : item[1]
              )}
              index={sort}
              selectIndex={setSort}
              setModal={setIsSortModal}
              isModalOpen={isSortModal}
            />
          )}
        </div>
        <ul className=" flex flex-col gap-1 py-1 list-none h-[811.3px]">
          {data.length > 0 && !isValid ? (
            <li>
              <ListComponents slice={10} data={data} />
              <DividerButton
                text={t("viewMore")}
                onClick={() => {
                  router.push(
                    `/high-risk?type=${
                      type === CRYPTO
                        ? "crypto"
                        : type === KOREA
                        ? "kr"
                        : type === US
                        ? "us"
                        : ""
                    }`
                  );
                }}
              />
            </li>
          ) : !isValid && data.length === 0 ? (
            <li>
              <div className="py-10 flex justify-center items-center">
                해당되는 자산이 존재하지 않습니다
              </div>
            </li>
          ) : (
            <li className="h-full">
              <Loading className="h-full" />
              <DividerLine size="md" />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default RiskTable;
