import useSWR from "swr";
import { fetcher } from "./fetcher";

const useTrendingAsset = (router: any): [any, boolean] => {
    const { data: explodeDt, isValidating: isValid } = useSWR(
        `/api/trending`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const data = explodeDt ? [].concat(...explodeDt) : [];
    let filtered: any[] = [];
    if (router.locale === 'ko') {
        filtered = data.filter((item: any) => item.ITEM_CD.toString()[0] == 5);
    }
    else {
        filtered = data.filter((item: any) => item.ITEM_CD.toString()[0] == 2);
    }
    filtered.sort((a, b) => a.RANK - b.RANK);
    return [filtered, isValid];
}

export default useTrendingAsset;